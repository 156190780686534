<template>
  <div class="app-container h">
    <div class="v no-flex" style="width: 320px; margin-right: 20px;">
      <div class="bold" style="line-height: 2;">自定义区域</div>
      <div class="scroll-able-xy flex" style="border: 1px solid #DCDFE6; border-radius: 4px;">
        <dept-select v-model="query.treeNodeId" :type.sync="query.treeNodeType" @change="toQuery" />
      </div>
    </div>

    <div class="flex v">
      <el-form class="head-container e" label-position="top">
        <el-form-item label="时间范围" class="filter-item">
          <div class="h c">
            <el-date-picker v-model="query.dateRange" type="daterange" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="dateRangeOptions" value-format="timestamp" :default-time="['00:00:00', '23:59:59']" :clearable="false" @change="toQuery" />
            <!-- <el-date-picker v-model="query.begDate" type="date" value-format="timestamp" placeholder="选择开始日" :clearable="true" style="width:150px" @change="toQuery" />
            <div class="padding-0-05">至</div>
            <el-date-picker v-model="query.endDate" type="date" value-format="timestamp" placeholder="选择结束日" :clearable="true" style="width:150px" @change="toQuery" />-->
          </div>
        </el-form-item>

        <el-form-item label="仓库类型" class="filter-item">
          <quick-select v-model="query.warehouseType" :options="warehouseTypes" clearable style="width: 160px" @change="toQuery" />
        </el-form-item>

        <el-form-item label="占比" class="filter-item">
          <el-radio-group v-model="showOrNotRatio" @change="toQuery">
            <el-radio-button :label="false">隐藏</el-radio-button>
            <el-radio-button :label="true">显示</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item class="filter-item">
          <el-button type="success" icon="el-icon-search" :loading="loading" @click="toQuery">统计</el-button>
          <el-button type="info" icon="el-icon-download" :loading="downloadLoading" @click="toDownload">导出</el-button>
        </el-form-item>
      </el-form>

      <el-table ref="table" v-loading="loading" :data="reportDatas" height="500" @sort-change="handleSortChange" row-key="id" border lazy :load="load" style="min-height: 360px" :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
        <el-table-column align="left" label="项目" width="210px;" show-overflow-tooltip fixed="left">
          <template slot-scope="scope">{{scope.row.purchase ? scope.row.purchase.name : scope.row.sale ? scope.row.sale.name : scope.row.warehouse ? scope.row.warehouse.name : "--"}}</template>
        </el-table-column>
        <el-table-column align="center" label="销售">
          <el-table-column align="center" prop="sale.money" label="含税金额（元）" :formatter="$price" width="130px;" sortable="custom" />
          <el-table-column align="center" prop="sale.moneyRatio" label="占比" width="130px;" sortable="custom" v-if="showOrNotRatio">
            <template slot-scope="scope">{{scope.row.sale && scope.row.sale.moneyRatio ? (scope.row.sale.moneyRatio*100).toFixed(2)+"%" :"-"}}</template>
          </el-table-column>
          <el-table-column align="center" prop="sale.amount" label="数量（件）" width="130px;" sortable="custom">
            <template slot-scope="scope">{{$quantity(scope.row.sale ? scope.row.sale.amount : 0)}}</template>
          </el-table-column>

          <el-table-column align="center" prop="sale.amountRatio" label="占比" width="130px;" sortable="custom" v-if="showOrNotRatio">
            <template slot-scope="scope">{{scope.row.sale && scope.row.sale.amountRatio?(scope.row.sale.amountRatio*100).toFixed(2)+"%" :"-"}}</template>
          </el-table-column>
          <el-table-column align="center" prop="sale.avgMoney" label="含税均价" :formatter="$price" width="130px;" sortable="custom" />
        </el-table-column>

        <el-table-column align="center" label="采购">
          <el-table-column align="center" prop="purchase.money" label="含税金额（元）" :formatter="$price" width="130px;" sortable="custom" />
          <el-table-column align="center" label="占比" width="130px;" sortable="custom" v-if="showOrNotRatio">
            <template slot-scope="scope">{{scope.row.purchase && scope.row.purchase.moneyRatio?(scope.row.purchase.moneyRatio*100).toFixed(2)+"%" :"-"}}</template>
          </el-table-column>
          <el-table-column align="center" prop="purchase.amount" label="数量（件）" width="130px;" sortable="custom">
            <template slot-scope="scope">{{$quantity(scope.row.purchase ? scope.row.purchase.amount : 0)}}</template>
          </el-table-column>

          <el-table-column align="center" prop="purchase.amountRatio" label="占比" width="130px;" sortable="custom" v-if="showOrNotRatio">
            <template slot-scope="scope">{{scope.row.purchase && scope.row.purchase.amountRatio?(scope.row.purchase.amountRatio*100).toFixed(2)+"%" :"-"}}</template>
          </el-table-column>
          <el-table-column align="center" prop="purchase.avgMoney" label="含税均价" :formatter="$price" width="130px;" sortable="custom" />
        </el-table-column>

        <el-table-column align="center" label="库存量">
          <el-table-column align="center" prop="warehouse.money" label="含税金额（元）" :formatter="$price" width="130px;" sortable="custom" />
          <el-table-column align="center" prop="warehouse.moneyRatio" label="占比" width="130px;" sortable="custom" v-if="showOrNotRatio">
            <template slot-scope="scope">{{scope.row.warehouse && scope.row.warehouse.moneyRatio?(scope.row.warehouse.moneyRatio*100).toFixed(2)+"%" :"-"}}</template>
          </el-table-column>
          <el-table-column align="center" prop="warehouse.amount" label="数量（件）" width="130px;" sortable="custom">
            <template slot-scope="scope">{{$quantity(scope.row.warehouse ? scope.row.warehouse.amount : 0)}}</template>
          </el-table-column>
          <el-table-column align="center" prop="warehouse.amountRatio" label="占比" width="130px;" sortable="custom" v-if="showOrNotRatio">
            <template slot-scope="scope">{{scope.row.warehouse && scope.row.warehouse.amountRatio?(scope.row.warehouse.amountRatio*100).toFixed(2)+"%" :"-"}}</template>
          </el-table-column>
          <el-table-column align="center" prop="warehouse.avgMoney" label="含税均价" :formatter="$price" width="130px;" sortable="custom" />
        </el-table-column>
      </el-table>

      <div class="padding-10-0 h c" v-if="trendQuery">
        <div class="flex bold">{{new Date(trendQuery.begDate).format("yyyy-MM-dd")}}至{{new Date(trendQuery.endDate).format("yyyy-MM-dd")}}进销存走势图（按周统计）</div>
        <el-radio-group v-model="trendType" @change="drawTrend">
          <el-radio-button label="money">金额（万元）</el-radio-button>
          <el-radio-button label="amount">数量</el-radio-button>
        </el-radio-group>
      </div>
      <div ref="trendChart" style="height: 220px;"></div>
    </div>
  </div>
</template>
<script>
import deptSelect from "./deptSelect";
import request from "@/utils/request";
import { download } from "@/api/data";
import { downloadFile } from "@/utils/index";
export default {
  name: "purchaseAll",
  components: { deptSelect },

  data() {
    let now = new Date();
    return {
      loading: false,
      downloadLoading: false,
      reportDatas: [],
      trendData: [],
      trendQuery: null,
      trendType: "money",
      trendTypeMaps: {
        money: "金额",
        amount: "数量",
      },
      trendSeriesMaps: {
        purchase: "采购",
        sale: "销售",
        warehouse: "库存",
      },
      trendChart: null,
      downloadDadas: [],
      showOrNotRatio: false,
      classIdOne: null,
      classIdTwo: null,
      classIdThree: null,
      params: null,
      currentSortColumn: null,
      warehouseTypes: [
        { id: 0, name: "中央库房" },
        { id: 1, name: "零售店" },
      ],
      //时间查询
      dateRangeOptions: {
        shortcuts: [
          {
            text: "最近上一周",
            onClick(picker) {
              let d = new Date();
              const start = new Date(
                d.getFullYear(),
                d.getMonth(),
                d.getDate() - 6 - (d.getDay() == 0 ? 7 : d.getDay()),
                0,
                0,
                0
              );
              const end = new Date(
                d.getFullYear(),
                d.getMonth(),
                d.getDate() - (d.getDay() == 0 ? 7 : d.getDay()),
                23,
                59,
                59
              );
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      query: {
        dateRange: [
          new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate() -
              6 -
              (new Date().getDay() == 0 ? 7 : new Date().getDay()),
            0,
            0,
            0
          ).getTime(),
          new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate() -
              (new Date().getDay() == 0 ? 7 : new Date().getDay()),
            23,
            59,
            59
          ).getTime(),
        ],
        treeNodeId: null,
        treeNodeType: null,
        warehouseType: null,
        groupType: 0,
      },
    };
  },
  mounted() {
    this.toQuery();
  },
  methods: {
    toQuery() {
      if (this.query.treeNodeType && this.query.treeNodeId) {
        this.reportDatas = [];
        this.loading = true;
        let query = JSON.parse(JSON.stringify(this.query));
        query.sort = this.sort;
        if (query.dateRange && query.dateRange.length === 2) {
          query.begDate = query.dateRange[0];
          query.endDate = query.dateRange[1];
        }
        delete query.dateRange;
        request({
          url: "@host:analysis;api/analy/purchase/all",
          method: "get",
          params: query,
        })
          .then((res) => {
            let total = {
              id: this.$uuid(),
              _groupType: -1,
              hasChildren: false,
              sale: {
                amount: 0,
                amountRatio: 1,
                avgMoney: 0,
                money: 0,
                moneyRatio: 1,
                name: "合计",
              },
              purchase: {
                amount: 0,
                amountRatio: 1,
                avgMoney: 0,
                money: 0,
                moneyRatio: 1,
                name: "合计",
              },
              warehouse: {
                amount: 0,
                amountRatio: 1,
                avgMoney: 0,
                money: 0,
                moneyRatio: 1,
                name: "合计",
              },
            };
            res.forEach((o) => {
              o.id = this.$uuid();
              o._groupType = 1;
              o.hasChildren = true;
              o.children = [];
              if (o.sale) {
                total.sale.amount += o.sale.amount || 0;
                total.sale.money += o.sale.money || 0;
              }
              if (o.purchase) {
                total.purchase.amount += o.purchase.amount || 0;
                total.purchase.money += o.purchase.money || 0;
              }
              if (o.warehouse) {
                total.warehouse.amount += o.warehouse.amount || 0;
                total.warehouse.money += o.warehouse.money || 0;
              }
            });
            total.sale.avgMoney = Math.round(
              total.sale.money / total.sale.amount
            );
            total.purchase.avgMoney = Math.round(
              total.purchase.money / total.purchase.amount
            );
            total.warehouse.avgMoney = Math.round(
              total.warehouse.money / total.warehouse.amount
            );
            res.push(total);
            this.loading = false;
            this.reportDatas = res;
          })
          .catch((err) => {
            this.loading = false;
            this.reportDatas = [];
          })
          .finally(() => {
            this.loading = false;
            this.$refs.table && this.$refs.table.doLayout();
          });

        let q1 = Object.assign({}, query);
        let ed = new Date(q1.endDate);
        q1.begDate = new Date(
          ed.getFullYear(),
          ed.getMonth(),
          ed.getDate() - 55
        ).getTime();
        this.trendQuery = q1;
        request({
          url: "@host:analysis;api/analy/purchase/week/byTrend",
          method: "get",
          params: q1,
        }).then((res) => {
          this.trendData = res;
          this.drawTrend();
        });
      }
    },
    load(tree, treeNode, resolve) {
      let query = JSON.parse(JSON.stringify(this.query));
      if (query.dateRange && query.dateRange.length === 2) {
        query.begDate = query.dateRange[0];
        query.endDate = query.dateRange[1];
      }
      delete query.dateRange;
      if (tree._groupType == 1) {
        this.classIdOne =
          (tree.purchase && tree.purchase.id) ||
          (tree.sale && tree.sale.id) ||
          (tree.warehouse && tree.warehouse.id) ||
          "";
        this.params = Object.assign(
          {
            classId: this.classIdOne,
          },
          query,
          {
            groupType: tree._groupType + 1,
          }
        );
      } else if (tree._groupType == 2) {
        this.classIdTwo = tree.purchase
          ? tree.purchase.id
          : tree.sale
          ? tree.sale.id
          : tree.warehouse
          ? tree.warehouse.id
          : "";
        this.params = Object.assign(
          {
            classId: this.classIdOne,
            seriesId: this.classIdTwo,
          },
          query,
          {
            groupType: tree._groupType + 1,
          }
        );
      } else if (tree._groupType == 3) {
        this.classIdThree =
          (tree.purchase && tree.purchase.id) ||
          (tree.sale && tree.sale.id) ||
          (tree.warehouse && tree.warehouse.id) ||
          "";
        this.params = Object.assign(
          {
            spuId: this.classIdThree,
          },
          query,
          {
            groupType: tree._groupType + 1,
          }
        );
      }
      request({
        url: "@host:analysis;api/analy/purchase/all",
        method: "get",
        params: this.params,
      }).then((res) => {
        if (res.length > 0) {
          res.forEach((ry) => {
            ry.id = this.$uuid();
            ry._groupType = tree._groupType + 1;
            this.downloadDadas.push(ry);
            if (ry._groupType < 3) {
              ry.hasChildren = true;
              ry.children = [];
            }
          });
          resolve(res);
        } else {
          resolve([]);
        }
        this.$nextTick(() => {
          if (this.currentSortColumn) {
            this.handleSortChange(this.currentSortColumn);
          }
        });
      });
    },

    drawTrend() {
      if (!this.trendChart) {
        this.trendChart = echarts.init(this.$refs.trendChart, "light");
      }
      this.trendChart.clear();

      if (this.trendData && this.trendData.length) {
        let xData = [],
          series = [],
          s = {};
        this.trendData.forEach((o) => {
          for (let k in o) {
            if (!s.hasOwnProperty(k)) {
              s[k] = {
                type: "line",
                smooth: true,
                name:
                  this.trendSeriesMaps[k] + this.trendTypeMaps[this.trendType],
                data: [],
              };
              series.push(s[k]);
            }
            if (o[k]) {
              let d = o[k];
              if (xData.indexOf(d.name) < 0) xData.push(d.name);
              s[k].data.push({
                name: d.name,
                value:
                  this.trendType === "money"
                    ? Math.round(d.money / 10000) / 100
                    : Math.round(d[this.trendType]),
              });
            }
          }
        });

        this.trendChart.setOption({
          grid: {
            top: 10,
            bottom: 0,
            left: 20,
            right: 50,
            containLabel: true,
          },
          tooltip: {
            trigger: "axis",
          },
          xAxis: {
            type: "category",
            data: xData,
            boundaryGap: false,
          },
          yAxis: {
            type: "value",
            name: this.trendType === "money" ? "金额（万元）" : "数量",
          },
          series,
        });
      }
    },

    getDataVal(item, path) {
      let ps = path.split("."),
        ov = item[ps[0]],
        inx = 1;
      while (ov && inx <= ps.length - 1) {
        ov = ov[ps[inx]];
        inx++;
      }
      return ov;
    },
    handleSortChange(column) {
      if (column.order === "ascending" || column.order === "descending") {
        this.currentSortColumn = column;
        this.reportDatas.sort((a, b) => {
          return a._groupType < b._groupType
            ? 1
            : (this.getDataVal(a, column.prop) < this.getDataVal(b, column.prop)
                ? -1
                : 1) * (column.order === "ascending" ? 1 : -1);
        });

        let store = this.$refs.table.store.states.lazyTreeNodeMap;
        for (let k in store) {
          store[k] = store[k].sort((a, b) => {
            let aval = this.getDataVal(a, column.prop),
              bval = this.getDataVal(b, column.prop);
            return (
              (aval < bval ? -1 : 1) * (column.order === "ascending" ? 1 : -1)
            );
          });
        }
      } else {
        this.currentSortColumn = null;
      }
    },

    getExportRowData(v, level) {
      let indent = "        ".substr(0, level * 2);
      return [
        indent +
          (v.sale
            ? v.sale.name
            : v.purchase
            ? v.purchase.name
            : v.warehouse
            ? v.warehouse.name
            : "--"),
        v.sale ? this.$price(v.sale.money) : "--",
        v.sale ? (v.sale.moneyRatio * 100).toFixed(2) + "%" : "--",
        v.sale ? v.sale.amount.toFixed(2) : "--",
        v.sale ? (v.sale.amountRatio * 100).toFixed(2) + "%" : "--",
        v.sale ? this.$price(v.sale.avgMoney) : "--",
        v.purchase ? this.$price(v.purchase.money) : "--",
        v.purchase ? (v.purchase.moneyRatio * 100).toFixed(2) + "%" : "--",
        v.purchase ? v.purchase.amount.toFixed(2) : "--",
        v.purchase ? (v.purchase.amountRatio * 100).toFixed(2) + "%" : "--",
        v.purchase ? this.$price(v.purchase.avgMoney) : "--",
        v.warehouse ? this.$price(v.warehouse.money) : "--",
        v.warehouse ? (v.warehouse.moneyRatio * 100).toFixed(2) + "%" : "--",
        v.warehouse ? v.warehouse.amount.toFixed(2) : "--",
        v.warehouse ? (v.warehouse.amountRatio * 100).toFixed(2) + "%" : "--",
        v.warehouse ? this.$price(v.warehouse.avgMoney) : "--",
      ];
    },
    // 导出
    //
    toDownload() {
      if (this.data && this.data.length === 0) {
        this.$message.info("当前无数据导出");
        return;
      }
      this.downloadLoading = true;
      download("@host:analysis;api/analy/purchase/all/download", this.query)
        .then((result) => {
          downloadFile(result, "管理报告", "xlsx");
          this.downloadLoading = false;
        })
        .catch((err) => {
          this.$message.error(err.message || "操作失败，请稍候再试。");
          this.downloadLoading = false;
        });
    },
    // download() {
    //   this.downloadLoading = true;
    //   import("@/utils/export2Excel").then((excel) => {
    //     const tHeader = [
    //       "项目",
    //       "销售金额（元）",
    //       "销售金额占比",
    //       "销售数量",
    //       "销售数量占比",
    //       "销售均价",
    //       "采购金额（元）",
    //       "采购金额占比",
    //       "采购数量",
    //       "采购数量占比",
    //       "采购均价",
    //       "库存金额（元）",
    //       "库存金额占比",
    //       "库存数量",
    //       "库存数量占比",
    //       "库存均价",
    //     ];
    //     const data = [],
    //       store = this.$refs.table.store.states.lazyTreeNodeMap;
    //     let fn = (ds, level = 0) => {
    //       ds.forEach((d) => {
    //         data.push(this.getExportRowData(d, level));
    //         if (store.hasOwnProperty(d.id)) {
    //           fn(store[d.id], level + 1);
    //         }
    //       });
    //     };
    //     fn(this.reportDatas);
    //     let filename = "管理报告";
    //     if (this.query.dateRange && this.query.dateRange.length === 2) {
    //       filename =
    //         "管理报告_" +
    //         new Date(this.query.dateRange[0]).format("yyyy/MM/dd") +
    //         "~" +
    //         new Date(this.query.dateRange[1]).format("yyyy/MM/dd");
    //     }
    //     excel.export_json_to_excel({
    //       header: tHeader,
    //       data: data,
    //       filename,
    //     });
    //     this.downloadLoading = false;
    //   });
    // },
  },
};
</script>